



















































































































.Img2 { // debug
	[showBorders2] & {}

	//background-color: fade( blue, 25 );
	//outline: 1px solid fade( blue, 25 );
	border: 3px solid fade( blue, 25 );
}
.Img2 { // vars
}
.Img2 { // layout
	max-width: 100%;
}
.Img2 { // styling
	transition: opacity 0.5s ease 0.15s;
	object-fit: cover;
	//opacity: 0;

	&--isNotLoaded { background-color: fade( black, 15 ); }
	&--isLoaded { opacity: 1; }
}
